<template>
  <v-container fluid>
    <tabs
        :currentTab="currentTab"
        :line-class="'tab-item-line'"
        :tab-active-class="'tab-item-active'"
        :tab-class="'tab-item'"
        :tabs="this.tabs"
        :wrapper-class="'body-tabs shadow-tabs'"
        @onClick="handleClick"
    />

    <div class="content mt-6 pa-0">
      <div v-if="currentTab === 'tab1'">
        <Salutation></Salutation>
      </div>
      <div v-if="currentTab === 'tab2'">
        <Department></Department>
      </div>

    </div>

  </v-container>
</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import Salutation from "./salutation/Salutation";
import Department from "./department/Department";

export default {
  components: {
    Tabs,
    Salutation,
    Department
  },
  name: "SettingsComponent",
  data: () => {
    return {

      currentTab: 'tab1',
    }
  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('customers.lang_salutationSettings'),
          value: 'tab1',
        },
        {
          title: this.$t('customers.lang_customerDepartments'),
          value: 'tab2',
        },

      ];
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    }
  }
}
</script>

<style scoped>
.tabs > .tabs__item {
  width: auto !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

</style>