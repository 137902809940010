<template>
  <v-container fluid>
    <v-card elevation="6" outlined>
      <v-card-title>
        <v-col>{{$t('customers.lang_availableDepartments')}}</v-col>
      <v-col align="right">
        <v-btn
            color="success"
            class="ma-2 white--text"
            @click="Create"
        >
          {{$t('generic.lang_create')}}
          <v-icon
              right
              dark
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <Datatable ref="DepartmentDatatable"
                   :api-endpoint="ENDPOINTS.DATATABLES.CUSTOMERS.CUSTDEPARTMENTS"
                   :datatable-headers="datatableHeaders"
                   :excel-columns="excelColumns"
                   excel-file-name="Customers Department"
                   @editEntry="entryEdit"
                   @deleteEntry="entryDelete"
                   @deleteEntries="entriesDelete"
                   show-delete-buttons
                   show-edit-buttons

        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '../../../../config'
import {Events} from "../../../../plugins/events";
import mixin from '../../../../mixins/KeyboardMixIns';
import Datatable from "../../../datatable/Datatable";

export default {
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      id:null,
      loading: false,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
        },
        {text: this.$t('customers.lang_departmentName'), value: 'name',sort: "asc"},
      ],
      excelColumns: [

        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('customers.lang_departmentName'),
          field: "name",
        },
      ],
    }
  },
  methods: {
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('customers.lang_departmentDeleteHeader'),
        text: this.$t('customers.lang_departmentDeleteBody'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.DELETE, {
            departmentId: idsToDelete
          }).then((res) => {
            if (res.data.STATUS === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('customers.lang_departmentDeletedSuc'),
                color: "success"
              });
              this.id=null;
              self.$refs.DepartmentDatatable.getDataFromApi();
              self.$refs.DepartmentDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    entryEdit(entry) {
      this.$router.push({name: 'customer.customerDepartment.edit', params: {id: parseInt(entry.id)}});
    },
    Create() {
      this.$router.push({name: 'customer.customerDepartment.create'});
    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  }
}
</script>