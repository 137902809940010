<template>
  <div>
    <page-title :heading="$t('customers.lang_customerSettings') "  :subheading="$t('customers.lang_customerSettings')" :icon=icon></page-title>
    <div class="app-main__inner">
      <CustomerSettings></CustomerSettings>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import CustomerSettings from "../../../components/customers/customersettings/CustomerSettings";

export default {
  components: {
    PageTitle,
    CustomerSettings
  },
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>